import React from 'react';
import { Link } from 'react-router-dom';
import Content from '../common/containers/Content';
import PrivatePage from '../common/containers/PrivatePage';
import Document from './components/Document';
import Ajax from '../common/ajax';
import Config from '../config';
import PaginationWithClick from '../common/components/PaginationWithClick';
import LocaleUtils from '../common/LocaleUtils';
import Button from '../common/components/Button';
import URLS from '../urls';
import { AlertError } from '../common/components/Alert';
import { clearDjangoValidationMessages, showDjangoValidationMessages } from '../common/validation';
import Row from '../common/containers/Row';
import Col from '../common/containers/Col';
import Panel from '../common/containers/Panel';
import { withNamespaces } from 'react-i18next';
import ModalConfirm from '../common/containers/ModalConfirm';
import Waiting from '../common/containers/Waiting';

class DocumentRevisionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
      revisionProcessing: true,
      showNewRevisionModal: false,
      name: '',
      currentRevisionsUrl: '',
      errorMessage: '',
    };
    this.fetchRevisions = this.fetchRevisions.bind(this);
    this.showNewRevisionModal = this.showNewRevisionModal.bind(this);
    this.closeNewRevisionModal = this.closeNewRevisionModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.createNewRevision = this.createNewRevision.bind(this);
    this.removeRevision = this.removeRevision.bind(this);
    this.onFinalizeRevisionToggle = this.onFinalizeRevisionToggle.bind(this);
  }

  componentDidMount() {
    this.fetchRevisions();
  }

  fetchRevisions(url) {
    if (!url) {
      const documentId = this.props.match.params.documentId;
      url = `${Config.apiHost}revisions/?document=${documentId}`;
    }

    this.setState((state) => ({
      ...state,
      revisionProcessing: true,
      currentRevisionsUrl: url,
    }));
    Ajax.get(url).done((pagination) => {
      this.setState((state) => ({
        ...state,
        pagination: pagination,
        revisions: pagination.results,
        revisionProcessing: false,
      }));
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState((state) => ({
      ...state,
      [name]: value,
    }));
  }

  onFinalizeRevisionToggle(revision) {
    this.setState((state) => ({
      ...state,
      revisionProcessing: true,
    }));
    revision.finished = !revision.finished;
    const url = `${Config.apiHost}revisions/${revision.id}/`;
    Ajax.patch(url, { finished: revision.finished }).always(() => {
      this.setState((state) => ({
        ...state,
        revisionProcessing: false,
      }));
    });
  }

  showNewRevisionModal() {
    this.setState((state) => ({
      ...state,
      showNewRevisionModal: true,
    }));
  }

  closeNewRevisionModal() {
    this.setState((state) => ({
      ...state,
      showNewRevisionModal: false,
      name: '',
    }));
  }

  createNewRevision(evt) {
    clearDjangoValidationMessages();

    this.setState((state) => ({
      ...state,
      revisionProcessing: true,
      errorMessage: null,
    }));

    const url = `${Config.apiHost}revisions/`;
    const data = {
      name: this.state.name,
      document: this.props.match.params.documentId,
    };

    Ajax.post(url, data)
      .done(() => {
        this.closeNewRevisionModal();
        this.fetchRevisions(this.state.currentRevisionsUrl);
      })
      .fail((response) => {
        showDjangoValidationMessages(response.responseJSON);
        this.setState(
          (state) => ({
            ...state,
            errorMessage: response.responseJSON.detail,
            revisionProcessing: false,
          }),
          () => console.log(this.state),
        );
      });
  }

  removeRevision(revision) {
    const { i18n } = this.props;
    const msg = `${i18n.t('Deseja realmente remover a revisão')} "${revision.name}"?`;
    if (confirm(msg)) {
      this.setState((state) => ({
        ...state,
        revisionProcessing: true,
      }));
      const url = `${Config.apiHost}revisions/${revision.id}/`;
      Ajax.delete_(url).done((response) => {
        this.fetchRevisions(this.state.currentRevisionsUrl);
      });
    }
  }

  renderRevisionRows() {
    const { i18n } = this.props;
    if (!this.state.revisions || this.state.revisions.length === 0) {
      return (
        <tr>
          <td colSpan="7">{i18n.t('Não há revisões para esse documento')}</td>
        </tr>
      );
    }
    return this.state.revisions.map((revision) => {
      return (
        <tr key={revision.id}>
          <td>
            <Link to={URLS.documentRevision(revision.id)}>{revision.name}</Link>
          </td>
          <td>
            <i className="mdi mdi-comment-alt-text" /> ({revision.total_annotations})
          </td>
          <td>{LocaleUtils.fromNow(revision.creation_date)}</td>
          <td>{revision.user.email}</td>
          <td>
            <div className="switch-button switch-button-xs switch-button-yesno">
              <input
                type="checkbox"
                checked={revision.finished}
                name={`switch-finished-${revision.id}`}
                id={`switch-finished-${revision.id}`}
                onChange={() => this.onFinalizeRevisionToggle(revision)}
              />
              <span>
                <label htmlFor={`switch-finished-${revision.id}`} />
              </span>
            </div>
          </td>
          <td>{revision.pdf_size_mb}</td>
          <td>
            <button className="btn btn-default btn-xs pull-right" onClick={() => this.removeRevision(revision)}>
              <i className="mdi mdi-delete" /> {i18n.t('Remover')}
            </button>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { i18n } = this.props;
    const tools = (
      <Button type="primary" onClick={this.showNewRevisionModal}>
        {i18n.t('Criar revisão')}
      </Button>
    );

    return (
      <PrivatePage title={i18n.t('Revisões')}>
        <Content>
          <Row>
            <Col md={10} mdOffset={1}>
              <Document documentId={this.props.match.params.documentId} isInTrash={false} />
            </Col>
          </Row>
          <Row>
            <Col md={10} mdOffset={1}>
              <Panel
                isPanelTable={true}
                isProcessing={this.state.revisionProcessing}
                title={i18n.t('Revisões do documento')}
                tools={tools}
              >
                <table className="table table-condensed table-striped">
                  <thead>
                    <tr>
                      <th>{i18n.t('Nome')}</th>
                      <th>{i18n.t('Comentários')}</th>
                      <th>{i18n.t('Data de criação')}</th>
                      <th>{i18n.t('Criado por')}</th>
                      <th>{i18n.t('Finalizado')}?</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>{this.renderRevisionRows()}</tbody>
                </table>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col md={10} mdOffset={1}>
              {this.state.pagination && (
                <PaginationWithClick handleClick={this.fetchRevisions} pagination={this.state.pagination} />
              )}
            </Col>
          </Row>
        </Content>

        <ModalConfirm
          show={this.state.showNewRevisionModal}
          type="noicon"
          title={i18n.t('Nova revisão')}
          onConfirm={this.createNewRevision}
          onCancel={this.closeNewRevisionModal}
        >
          <Waiting isProcessing={this.state.revisionProcessing}>
            <AlertError>{this.state.errorMessage}</AlertError>
            <div className="form-group">
              <input
                type="text"
                name="name"
                required={true}
                autoFocus={true}
                autoComplete="fuck-off"
                className="form-control"
                maxLength="254"
                placeholder={i18n.t('Nome da revisão')}
                onChange={this.handleInputChange}
                value={this.state.name}
              />
            </div>
          </Waiting>
        </ModalConfirm>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(DocumentRevisionList);
